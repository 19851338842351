import React from "react"
import Layout from "../components/layout"
import GalleryCard from "../components/galleryCard"

export default function OnDemand() {
  return (
    <Layout>
      <section className="content content--fullwidth gallery">
        <h2 className="page__heading">On Demand</h2>
        <h3 className="page__subheading">
          Squirrel Is A Next Generation RV Rental Company
        </h3>
        <div className="gallery-cards">
          <GalleryCard
            imgSrc="/technology/01-mapInitial.png"
            imgAlt="View of Squirrel app showing the browsing map"
            vertical
          >
            <ul className="gallery-card__list">
              <li className="gallery-card__list__body">Download the app</li>
              <li className="gallery-card__list__body">Sign up</li>
              <li className="gallery-card__list__body">
                Find a Squirrel close to you
              </li>
              <li className="gallery-card__list__body">
                View interior pics and amenities
              </li>
              <li className="gallery-card__list__body">Book your Squirrel</li>
              <li className="gallery-card__list__body">Unlock the doors using your phone</li>
            </ul>
          </GalleryCard>
        </div>
      </section>
    </Layout>
  )
}
